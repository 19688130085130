import { shallowEqual, useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import {
  EMPTY_CATEGORY_LABEL,
  useConceptLabelFormatter,
  useProductHierarchyGroupLabelFormatter,
  useProductHierarchyLabelFormatter,
  useSourceLabelFormatter,
  useTagLabelFormatter,
  useTagSetLabelFormatter,
} from 'reducers/entityLabelFormatter';
import {
  PERIODS_ITEMS,
  presetIsEmptySelector,
  searchResultsStatisticsSelector,
} from 'selectors/search';

import Link from 'components/ui/Link';
import FiltersSummary from 'components/ui/search/FiltersSummary';
import Statistics from 'components/ui/visualization/Statistics';

import commonPropTypes from 'utils/commonPropTypes';
import { formatFacetDates } from 'utils/formatter';

import * as svars from 'assets/style/variables';

function CheckIcon({ check, checkedColor }) {
  const props = check
    ? { name: 'check circle', style: { color: checkedColor } }
    : { name: 'circle', style: { color: svars.colorGrey } };
  return <Icon {...props} />;
}
CheckIcon.propTypes = {
  check: PropTypes.bool.isRequired,
  checkedColor: PropTypes.string.isRequired,
};

function PolaritiesIndicator({ negative, neutral, positive }) {
  return (
    <span>
      <CheckIcon check={negative} checkedColor={svars.absoluteMinColor} />
      <CheckIcon check={neutral} checkedColor={svars.absoluteMidColor} />
      <CheckIcon check={positive} checkedColor={svars.absoluteMaxColor} />
    </span>
  );
}

PolaritiesIndicator.propTypes = {
  negative: PropTypes.bool.isRequired,
  neutral: PropTypes.bool.isRequired,
  positive: PropTypes.bool.isRequired,
};

const getPolaritiesIndicator = (polarities) => (
  <PolaritiesIndicator
    positive={polarities.includes('positives')}
    negative={polarities.includes('negatives')}
    neutral={polarities.includes('neutrals')}
  />
);

/**
 * Sorts an array of tag IDs, placing the EMPTY_CATEGORY_LABEL first.
 *
 * @param {string[]} tagIds - The array of tag IDs to sort.
 * @returns {string[]} The sorted array of tag IDs.
 */
const sortTagIds = (tagIds) =>
  [...(tagIds || [])].sort((a, b) => {
    if (a === EMPTY_CATEGORY_LABEL) return -1;
    if (b === EMPTY_CATEGORY_LABEL) return 1;
    return a.localeCompare(b);
  });

function FiltersDescription({ filtersValues, onCloseModal }) {
  const sourceLabelFormatter = useSourceLabelFormatter();
  const conceptLabelFormatter = useConceptLabelFormatter();
  const productHierarchyLabelFormatter = useProductHierarchyLabelFormatter();
  const productHierarchyGroupLabelFormatter =
    useProductHierarchyGroupLabelFormatter();
  const tagLabelFormatter = useTagLabelFormatter();
  const tagSetLabelFormatter = useTagSetLabelFormatter();

  const searchResultsStatistics = useSelector(
    searchResultsStatisticsSelector,
    shallowEqual
  );
  const presetIsEmpty = useSelector(presetIsEmptySelector);
  const selectedPeriod = filtersValues.datePeriod
    ? PERIODS_ITEMS.find(({ value }) => filtersValues.datePeriod === value)
    : null;

  return (
    <FiltersSummary
      isEmpty={presetIsEmpty}
      title={t({ id: 'Filters' })}
      footerElement={
        <Statistics
          disabled={false}
          nChunks={searchResultsStatistics?.n_chunks}
          nChunksLabel={t({ id: 'Extracts' })}
          averageSentiment={
            searchResultsStatistics
              ? searchResultsStatistics.average_sentiment
              : '-'
          }
        />
      }
      emptyDataActionElement={
        <span>
          <p>
            <Trans>
              <Link base="true" onClick={onCloseModal}>
                Go back to search
              </Link>{' '}
              to add filters.
            </Trans>
          </p>
        </span>
      }
    >
      <FiltersSummary.OneFilter
        label={t({ id: 'keywords' })}
        value={filtersValues.textSearchValues}
      />
      <FiltersSummary.OneFilter
        label={t({ id: 'categories' })}
        value={filtersValues.ontologyConcepts}
        formatter={conceptLabelFormatter}
      />
      <FiltersSummary.OneFilter
        label={t({ id: 'products-services' })}
        value={filtersValues.productHierarchies}
        formatter={productHierarchyLabelFormatter}
      />
      <FiltersSummary.OneFilter
        multi={false}
        label={t({ id: 'period' })}
        value={selectedPeriod}
        formatter={({ i18nLabel }) => i18n._(i18nLabel)}
      />
      <FiltersSummary.OneFilter
        multi={false}
        label={t({ id: 'period' })}
        value={!selectedPeriod ? filtersValues : null}
        formatter={(value) => formatFacetDates(value.minDate, value.maxDate)}
      />
      <FiltersSummary.OneFilter
        label={t({ id: 'sources' })}
        value={filtersValues.sources}
        formatter={sourceLabelFormatter}
      />
      <FiltersSummary.OneFilter
        multi={false}
        label={t({ id: 'sentiment-polarity' })}
        value={filtersValues.polarities}
        formatter={getPolaritiesIndicator}
      />
      <FiltersSummary.OneFilter
        label={t({ id: 'product-services-group' })}
        value={filtersValues.productHierarchyGroups}
        formatter={productHierarchyGroupLabelFormatter}
      />
      {Object.entries(filtersValues.tags_map).map(([tagSetId, tagIds]) => (
        <FiltersSummary.OneFilter
          key={`fvti-${tagSetId}`}
          label={tagSetLabelFormatter(tagSetId)}
          value={sortTagIds(tagIds)}
          formatter={tagLabelFormatter}
        />
      ))}
    </FiltersSummary>
  );
}
FiltersDescription.propTypes = {
  filtersValues: commonPropTypes.searchFilterValues.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
FiltersDescription.defaultProps = {};

export default FiltersDescription;
